import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home';
import Shortcut from '@/pages/Home/Shortcut';
import Login from '@/pages/Login';
Vue.use(VueRouter);
export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: '首页',
            path: '/home',
            component: Home,
            meta: {
                metaInfo: {
                    title: '踏梦星合-简单的聚合搜索引擎！',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            },
            children: [
                {
                    name: '导航',
                    path: 'shortcut',
                    component: Shortcut,
                    meta: {
                        metaInfo: {
                            title: '工厂后台-商户数据主页',
                            keywords: '1311231',
                            description: '12312312'
                        }
                    }
                },
                {
                    path: '/home*',
                    redirect: '/home/shortcut'
                }
            ]
        },
        {
            name: '登录',
            path: '/login',
            component: Login,
            meta: {
                metaInfo: {
                    title: '踏梦星合-登陆注册',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            path: '*',
            redirect: '/home'
        }
    ]
});