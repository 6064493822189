import requests from './request';

import Qs from 'qs';

import { getCookie } from '@/utils/mycookie';
// 用户登录
export const userLogin = (d) => requests({ url: '/user/login', method: 'post', headers: { 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 刷新登录验证码
export const changeVerify = () => requests({ url: '/user/verify', method: 'get', headers: { 'x-codic': getCookie('x-myweb-key') } });
// 查询搜索关键词
export const getSg = (d) => requests({ url: 'https://suggestion.baidu.com/su?wd=' + d + '&action=opensearch', method: 'post', headers: {} });
// 添加城市
export const addCity = (d) => requests({ url: '/city/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加城市
export const getWeather = (d) => requests({ url: '/weather/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取热搜
export const getTrending = (d) => requests({ url: '/trend/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取搜索引擎列表
export const getEngines = (d) => requests({ url: '/enginetype/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 随机壁纸
export const randomWallpaper = (d) => requests({ url: '/wallpaper/random', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });



