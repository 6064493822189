import Vue from 'vue'
import App from './App.vue'
import router from '@/router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/store';
import VueMeta from 'vue-meta';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
iziToast.settings({
  'timeout': 3000,
  'backgroundColor': '#ffffff40',
  'titleColor': '#efefef',
  'messageColor': '#efefef',
  'progressBar': ![],
  'close': ![],
  'closeOnEscape': !![],
  'position': 'topCenter',
  'transitionIn': 'bounceInDown',
  'transitionOut': 'flipOutX',
  'displayMode': 'replace',
  'layout': '1'
});
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(ElementUI);
Vue.use(VueMeta);
Vue.use(iziToast);
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
}).$mount('#app')


