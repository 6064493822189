<template>
    <div class="codics-simple-home-body">
        <!-- <MyDialog :show.sync="engineDialog" :width="'200px'" :engine-dialog="engineDialog"
            :style="{'opacity':engineDialog ? '1' : '0','visibility':engineDialog ? 'visible' : 'hidden'}">
            <div style="height: 100px;"></div>
        </MyDialog> -->
        <div style="height: 100%;width: 100%;position: absolute;left: 0;top: 0;"
            @click="egnState = false; focusState = false;"></div>
        <!-- <el-image class="codics-simple-home-back-img" :src="nowWallpaper" fit="cover"
            :style="{ 'transform': backState ? 'scale(1.1)' : 'scale(1)', 'filter': backState ? 'blur(7px)' : 'unset', 'transition': backState ? 'ease 0.3s !important' : 'all 0.3s ease 0s' }"
            @click="egnState = false; focusState = false;"></el-image> -->
        <HomeHeader></HomeHeader>
        <div class="codics-simple-home-search-box">
            <el-carousel class="codics-simple-home-search-carousel" height="150px" indicator-position="none"
                :style="{ 'opacity': backState ? '.65' : '1','transform':backState ? 'scale(.85)' : 'scale(.9)' }"
                arrow="never">
                <el-carousel-item class="codics-simple-home-search-carousel-item"
                    :style="{ 'overflow': 'hidden !important' }">
                    <FlipClock class="codics-simple-home-time-view"></FlipClock>
                </el-carousel-item>
                <el-carousel-item
                    class="codics-simple-home-search-carousel-item codics-simple-home-search-carousel-item2">
                    <div class="codics-simple-home-search-carousel-item-wendu-txt">{{weatherInfo?.data?.wendu}}℃</div>
                    <el-image class="codics-simple-home-search-carousel-item-weather-img"
                        src="https://cdn.bosscaigou.com/%E7%AE%80%E5%8D%95%E6%90%9C%E7%B4%A2/img/weather_night_snow.png"></el-image>
                    <div class="codics-simple-home-search-carousel-item-weather-city iconfont">&#xe674; <span
                            style="font-size: 13px !important;">{{ weatherInfo?.cityInfo?.city || '未知'}}</span></div>
                    <div class="codics-simple-home-search-carousel-item-weather-city iconfont"
                        style="right: 0 !important;"><span style="font-size: 13px !important;">天气走势</span> &#xe603;
                    </div>
                    <div class="codics-simple-home-search-carousel-item-info-box">
                        <div class="codics-simple-home-search-carousel-item-info-item iconfont">&#xe61d; 空气质量：
                            {{weatherInfo?.data?.quality}}</div>
                        <div class="codics-simple-home-search-carousel-item-info-item iconfont">&#xe602; 风向风力：
                            {{ weatherInfo?.data?.forecast[0].fx + '' + weatherInfo?.data?.forecast[0].fl }}</div>
                        <div class="codics-simple-home-search-carousel-item-info-item iconfont">&#xed1c; 空气湿度：
                            {{ weatherInfo?.data?.shidu}}</div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="codics-simple-home-search-view" :data-content="nowTipTxt"
                :style="{'--tipOpcaity':backState ? '0' : '1'}">
                <div :class="['codics-simple-home-search-icon', 'iconfont']" @click="showEngine"
                    v-html="engineList[nowEngine].icon"></div>
                <input class="codics-simple-home-search-input" type="text" v-model="searchTxt" @focus="handleFocus"
                    @blur="handleBlur" @input="inputChange" @keydown.enter="searchFuc">
                <div class="codics-simple-home-search-delete iconfont"
                    :style="{ 'visibility': searchTxt == '' ? 'hidden' : 'visible' }"
                    @click="searchTxt = ''; suggestionData = [];">&#xe9cd;
                </div>
                <div class="codics-simple-home-search-search iconfont" @click="searchFuc">&#xe86e; 搜索一下</div>
            </div>
            <div class="codics-simple-home-search-tip-box codics-simple-home-search-engine-box"
                :style="{ 'height': (focusState | egnState) ? '200px' : '0', 'opacity': (focusState | egnState) ? '1' : '0' }"
                @click="focusState = false;">
                <div v-show="egnState" class="codics-simple-home-search-engine"
                    :style="{ 'backdrop-filter': egnState ? 'blur(10px) opacity(100%)' : 'blur(0px) opacity(0%)' }">
                    <div class="codics-simple-home-search-engine-top">
                        <div class="codics-simple-home-search-trending-top-name iconfont"
                            v-html="engineList[nowEngine].icon"></div>
                        <el-cascader class="codics-simple-home-search-trending-pick"
                            :props="{ label: 'name', value: 'value' }" v-model="nowTrending" :options="trendingOptions"
                            @change="trendingChange"></el-cascader>
                        <div v-show="!engineEditState"
                            class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                            @click="engineEditState = true; sortAble.option('disabled', false);">
                            &#xe7e5; 编辑</div>
                        <div v-show="engineEditState"
                            class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                            slot="reference" @click="engineEditState = false; sortAble.option('disabled', true);">
                            &#xe847; 确定</div>
                        <div v-show="engineEditState"
                            class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                            slot="reference" @click="engineEditState = false; sortAble.option('disabled', true);">
                            &#xe84a; 取消</div>
                        <el-popover v-show="!engineEditState" popper-class="codics-simple-popover-view"
                            placement="bottom" width="220" v-model="engineDialog">
                            <el-input class="codics-simple-popover-view-input" v-model="engineName"
                                placeholder="请输入引擎分类名称..." style="margin-bottom: 15px;"></el-input>
                            <div style="text-align: right; margin: 0">
                                <el-button type="success" size="mini" @click="visible = false">确定</el-button>
                            </div>
                            <div class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                                slot="reference">
                                &#xe811; 添加</div>
                        </el-popover>
                        <div v-show="engineEditState"
                            class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                            @click="trendingIndex = trendingIndex + 8 >= trendingData[nowTrending].length ? 0 : trendingIndex + 8">
                            &#xe850; 删除</div>
                    </div>
                    <div class="codics-simple-home-search-engine-view" ref="engineBox">
                        <div class="iconfont codics-simple-home-search-engine-item" :draggable="false"
                            v-for="(item, index) in engineList" :key="item.id">
                            <div class="codics-simple-home-search-engine-item-card"
                                v-html="item.icon + '  ' + item.name" @click="nowEngine = index; egnState = false;">
                            </div>
                            <div v-show="engineEditState" class="iconfont codics-simple-home-search-engine-item-delete">
                                &#xe84b;</div>
                        </div>
                    </div>
                </div>
                <div v-show="suggestionData.length != 0"
                    class="codics-simple-home-search-tip codics-simple-home-search-engine"
                    :style="{ 'pointer-events': focusState ? 'all' : 'none' }">
                    <a v-for="(item, index) in suggestionData" :key="index"
                        class="iconfont codics-simple-home-search-tip-item"
                        :style="{ 'padding-left': nowSugIndex == index ? '20px' : 'unset', 'background': nowSugIndex == index ? 'rgba(255, 255, 255, .3)' : 'unset' }"
                        v-html="'&#xe86e; ' + item.q +  (item.type=='direct_new' ? '<a style=\'color:#FF0000;padding-left:15px;position:relative;float:right;line-height:40px;\'>热</a>' : (item.type=='direct_hot' ? '<a style=\'color:#FF0000;padding-left:15px;font-size:20px;position:relative;float:right;line-height:40px;\'>热</a>' : ''))"
                        target="_blank" :href="'https://www.baidu.com/s?wd=' + item.q"></a>
                </div>
            </div>
            <div class="codics-simple-home-search-engine codics-simple-home-search-trending"
                :style="{  'opacity': !backState ? '1 !important' : '0 !important', 'pointer-events': !backState ? 'all !important' : 'none !important' }">
                <div class="codics-simple-home-search-trending-top">
                    <div class="codics-simple-home-search-trending-top-name iconfont">&#xe61c;</div>
                    <el-cascader class="codics-simple-home-search-trending-pick"
                        :props="{ label: 'name', value: 'value' }" v-model="nowTrending" :options="trendingOptions"
                        @change="trendingChange"></el-cascader>
                    <div class="codics-simple-home-search-trending-top-name codics-simple-home-search-trending-top-txt iconfont"
                        @click="trendingIndex = trendingIndex + 8 >= trendingData[nowTrending].length ? 0 : trendingIndex + 8">
                        &#xe6b2; 换一批</div>
                </div>
                <div class="codics-simple-home-search-trending-content">
                    <div class="codics-simple-home-search-trending-item"
                        v-for="(item,index) in (trendingData.length==0 ? 0 : trendingData[nowTrending]?.length % 8 == (trendingData[nowTrending]?.length - trendingIndex) ? (trendingData[nowTrending]?.length - trendingIndex) : 8)"
                        :key="index" @click="jumpToFuc(trendingData[nowTrending][trendingIndex + index])"
                        :title="trendingData[nowTrending][trendingIndex + index].showTitle">
                        <div class="codics-simple-home-search-trending-item-index"
                            :style="{'--backState':(trendingIndex==0 && index<3) ? '#FF0000' : 'rgba(0,0,0,0)'}">
                            {{trendingIndex + index + 1}}</div>
                        <span style="max-width: 40%;">{{ trendingData[nowTrending][trendingIndex + index].showTitle
                            }}</span>
                        <div class="codics-simple-home-search-trending-item-index codics-simple-home-search-trending-item-tag"
                            :style="{ '--backState': trendingData[nowTrending][trendingIndex + index].badge != '' ? (trendingData[nowTrending][trendingIndex + index].badge == 'jian_new' ? '#02AAE7' : '#FF0000') : 'rgba(0,0,0,0)','float':'right !important' }">
                            {{ trendingData[nowTrending][trendingIndex + index].badge == 'hot' ? '热' :
                            (trendingData[nowTrending][trendingIndex + index].badge == 'hotest' ? '爆' :
                            (trendingData[nowTrending][trendingIndex + index].badge == 'latest' ? '新' :
                            (trendingData[nowTrending][trendingIndex + index].badge == 'jian_new' ?
                            trendingData[nowTrending][trendingIndex + index].badgeText : ''))) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="codics-simple-home-left-container"
            :style="{ 'top': topState ? '-100vh' : '0', '--topState': topState ? '0' : 'rotate(180deg)' }">
            <router-view class="codics-simple-home-left-box"></router-view>
            <div class="iconfont codics-simple-home-left-container-min"
                :style="{ 'transform': topState ? 'translateY(-50%) translateX(-50%) scaleY(.8)' : 'translateY(-50%) translateX(-50%) scaleY(.8) rotateX(180deg)', filter: topState ? 'unset' : 'drop-shadow(0 -5px 1px rgba(0, 0, 0, 0.3))', 'opacity': topState ? 'unset' : '1 !important', top: topState ? '-10px' : '11px' }"
                @click="topState = !topState">&#xeb21;
                <div class="iconfont codics-simple-home-left-container-min-font">&#xe83e;</div>
            </div>
        </div>
        <div class="codics-simple-home-back-img-reload iconfont" @click="changeWallPaper"><span
                class="codics-simple-home-back-img-reload-icon"
                :style="{'transform':'rotate(' + rotateNum + 'deg)'}">&#xe6b2;</span> <span
                class="codics-simple-home-back-img-reload-txt">刷新壁纸</span></div>
        <div class="codics-simple-home-bottom-txt">
            © 2024&nbsp;&nbsp;<a href="#">山东华远创智信息技术有限公司</a>&nbsp;&nbsp;版权所有，并保留所有权利&nbsp;&nbsp;<a
                href="https://beian.miit.gov.cn/" style="text-decoration: none;">鲁ICP备2023021315号-3</a>
        </div>
    </div>
</template>

<script>
import iziToast from 'izitoast';
// 引入jsonp
import { jsonp, jsonpUtil, getRandomInt,jumpTo } from '@/utils/myutils';

import FlipClock from 'kuan-vue-flip-clock';

import { getWeather, getTrending } from '@/api';

import HomeHeader from '@/components/HomeHeader';

import { Flip } from '@/utils/flip';

import Sortable from 'sortablejs';

import MyDialog from '@/components/MyDialog';
export default {
    data() {
        return {
            rotateNum:0,
            engineName:'',
            engineDialog:false,
            nowSugIndex: -1,
            searchTxt: '',
            topState: true,
            focusState: false,
            egnState: false,
            suggestionData: [],
            nowEngine: 0,
            sortAble:null,
            engineList: [
                {
                    id: 1,
                    icon: '&#xe612;',
                    name: '百度',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 2,
                    icon: '&#xe66e;',
                    name: '必应',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 3,
                    icon: '&#xe728;',
                    name: '搜狗',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 4,
                    icon: '&#xe643;',
                    name: '360搜索',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 5,
                    icon: '&#xe63e;',
                    name: '微博',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 6,
                    icon: '&#xea8b;',
                    name: '知乎',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 7,
                    icon: '&#xea0b;',
                    name: 'GitHub',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 8,
                    icon: '&#xe6a5;',
                    name: '哔哩哔哩',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 9,
                    icon: '&#xe60e;',
                    name: '淘宝',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 10,
                    icon: '&#xe651;',
                    name: '京东',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 11,
                    icon: '&#xe664;',
                    name: '谷歌',
                    url: 'https://www.baidu.com/s?wd=%s'
                },
                {
                    id: 12,
                    icon: '&#xe678;',
                    name: '翻译',
                    url: 'https://www.baidu.com/s?wd=%s'
                }
            ],
            secendState: false,
            nowSecond: 0,
            nowSecond2: 0,
            weatherInfo: {},
            ipInfo: {},
            nowTrending: 'quanwang',
            trendingOptions: [
                {
                    name: '全部',
                    value: '全部',
                    children: [

                    ]
                }
            ],
            trendingData: [],
            trendingIndex: 0,
            iTimer: null,
            nowTipTxt: '搜点什么吧',
            sortState: true,
            engineEditState:false
        }
    },
    computed: {
        backState() {
            return this.egnState || this.focusState;
        },
        nowSecond1() { 
            return this.nowSecond + 1;
        }
    },
    components: {
        FlipClock,
        HomeHeader,
        MyDialog
    },
    mounted() {

        window.addEventListener("keydown", this.handleKeyDown);

        setTimeout(() => {

            iziToast.show({
                'title': 'hello',
                'message': '欢迎光临本站'
            });

        }, 300);

        this.getTrendingFuc();

        // jsonpUtil("ipGuiShuDi", 'https://whois.pconline.com.cn/ipJson.jsp?callback=ipGuiShuDi').then((result) => { 

        //     this.ipInfo = result;

            this.getWeatherFuc();

        // });

        

    },
    beforeDestroy() {

        window.removeEventListener("keydown", this.handleKeyDown);

        this.iTimer && this.stopTimer();

    },
    methods: {
        toPage(n, p, q) {

            jumpTo(this,n,p,q);

        },
        changeWallPaper() {

            this.rotateNum += 360;

            this.$emit('change-wall-paper');

         },
        initEngineSort() { 
            // 支持拖拽排序
            const el = this.$refs.engineBox;
            this.sortAble = Sortable.create(el, {
                animation: 200,
                disabled:true,
                onStart: (e) => { 
                    e.item.classList.add('moving');
                    // flip = new Flip(el.children, 0.5);
                },
                onEnd: (e) => {
                    const { oldIndex, newIndex } = e;
                    // 交换位置
                    const arr = this.engineList;
                    const page = arr[oldIndex];
                    arr.splice(oldIndex, 1);
                    arr.splice(newIndex, 0, page);
                    e.item.classList.remove('moving');
                }
            });

        },
        stopTimer() {
            if (this.iTimer) {
                clearInterval(this.iTimer); // 清除定时器
                this.iTimer = null; // 将定时器标识设置为null
            }
        },
        jumpToFuc(data) { 
            window.open('https://www.baidu.com/s?wd=' + data.showTitle, '_blank');
        },
        async getWeatherFuc() {

            let data = await getWeather({});

            if (data.code == 200) {

                this.weatherInfo = data.data;

            } else { 

                this.$message.error(data.message);

            }

        },
        async getTrendingFuc() { 

            let data = await getTrending({});

            if (data.code == 200) {

                this.trendingOptions[0].children = data.data.data['tops'];

                this.trendingData = data.data.data;

                if (!this.iTimer) {

                    let _this = this;

                    this.iTimer = setInterval(() => {

                        _this.nowTipTxt = _this.trendingData[this.nowTrending][getRandomInt(0, _this.trendingData[this.nowTrending].length - 1)].showTitle;

                    }, 3500);

                }

            } else {

                this.$message.error(data.message);

            }

        },
        trendingChange(a, b) { 

            this.trendingIndex = 0;

            this.nowTrending = a[a.length - 1];

        },
        showEngine() {

            this.egnState = true;

            this.suggestionData = [];

        },
        handleFocus() {

            this.focusState = true;

            this.egnState = false;

        },
        handleBlur() {

            // this.focusState = false;

            // this.suggestionData = [];

            // console.log("测试", this.searchTxt)

            if (this.searchTxt == '') {

                this.suggestionData = [];

            }

        },
        inputChange(a, b) {

            this.getSgFuc();

            if (this.searchTxt == '') {

                this.suggestionData = [];

            }

        },
        callbackFunction() {

        },
        async getSgFuc() {

            if (this.searchTxt == '') {

                this.suggestionData = [];

                return;

            }

            jsonp('https://www.baidu.com/sugrec?prod=pc&wd=' + encodeURI(this.searchTxt) + '&cb=jsonCallBack').then((result) => {

                console.log(result)

                this.suggestionData = result.g || [];

            });

        },
        searchFuc() {

            if (this.searchTxt == '') {

                window.open('https://www.baidu.com/s?wd=' + this.nowTipTxt, '_blank');

                return;

            }

            window.open('https://www.baidu.com/s?wd=' + this.searchTxt, '_blank');

        },
        handleKeyDown(event) {

            switch (event.key) {

                case 'ArrowUp':

                    this.nowSugIndex = this.nowSugIndex > 0 ? this.nowSugIndex - 1 : this.suggestionData.length - 1;

                    this.searchTxt = this.suggestionData[this.nowSugIndex].q;

                    break;

                case 'ArrowDown':

                    this.nowSugIndex = this.nowSugIndex < this.suggestionData.length - 1 ? this.nowSugIndex + 1 : 0;

                    this.searchTxt = this.suggestionData[this.nowSugIndex].q;

                    break;

            }

        }

    },
    watch: { 
        egnState(newVal, oldVal) { 

            if (newVal && this.sortState) { 

                this.sortState = false;

                this.initEngineSort();

            }

        },
        backState(newVal, oldVal) { 

            this.$emit("change-back-state",newVal)

            console.log('22222',newVal)

        }
    }
}
</script>

<style>
/* 搜索主页主体 */
.codics-simple-home-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    /* background-image: url('../../../public/img/geometry.webp');/ */
    /* 替换为你的图片路径 */
    background-repeat: repeat;
    /* 让背景图片平铺 */
}

/* 主页搜索盒子 */
.codics-simple-home-search-box {
    height: fit-content;
    width: 90%;
    max-width: 900px;
    position: absolute;
    margin: auto;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    transition: .4s;
}

/* 主页搜索框 */
.codics-simple-home-search-view {
    height: 42px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border-radius: 21px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    /* border-radius: 10px; */
    border: 1px solid rgba(255, 255, 255, 0.18);
}

/* 主页搜索框提示 */
.codics-simple-home-search-view::after{
    content: attr(data-content);
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 40px;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    pointer-events: none;
    opacity: var(--tipOpcaity);
    transition: .4s;
}

/* 搜索框图标 */
.codics-simple-home-search-icon {
    height: 42px;
    width: 70px;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    user-select: none;
    cursor: pointer;
}

/* 搜索框图标分割线 */
.codics-simple-home-search-icon::after {
    content: '';
    height: 14px;
    width: 1px;
    position: absolute;
    margin: auto;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .5);
}

/* 搜索输入框 */
.codics-simple-home-search-input {
    height: 100%;
    width: calc(100% - 230px);
    position: relative;
    float: left;
    line-height: 40px !important;
    padding-left: 15px;
    background: none !important;
    outline: none;
    border: none !important;
    /* transform: skewX(-45deg); */
    color: #FFF;
    white-space: nowrap;
    box-sizing: border-box;
    min-height: 0;
    font-size: 14px;
}

/* 搜索清空按钮 */
.codics-simple-home-search-delete {
    height: 100%;
    width: 40px;
    position: relative;
    float: left;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #FFF;
    line-height: 40px;
    cursor: pointer;
    user-select: none;
}

/* 搜索按钮 */
.codics-simple-home-search-search {
    height: 100%;
    width: 120px;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #FFF;
    user-select: none;
    cursor: pointer;
}

/* 搜索引擎切换盒子 */
.codics-simple-home-search-engine-box {
    height: 200px;
    transition: .4s;
    width: 100%;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 0px;
}

/* 搜索引擎容器 */
.codics-simple-home-search-engine {
    height: fit-content;
    width: 100%;
    position: absolute;
    top: 10px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25) !important;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
    transition: backdrop-filter 2s;
}

/* 搜索引擎列表容器 */
.codics-simple-home-search-engine-view{
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 7.5px 7.5px 7.5px;
}

/* 搜索主页顶栏容器 */
.codics-simple-home-left-container {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    transition: .4s;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
    background: rgba(255, 255, 255, 0.25) !important;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    /* border-radius: 10px; */
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
    perspective: 800px;
}

/* 侧边栏收起按钮 */
.codics-simple-home-left-container-min {
    height: 30px;
    width: 80px;
    position: relative;
    float: left;
    box-sizing: border-box;
    left: 50%;
    /* top: -10px; */
    transform: translateY(-50%) scaleY(.8);
    transform-origin: center center;
    cursor: pointer;
    font-size: 80px;
    opacity: .3 !important;
    color: #FFF;
    transition: .3s;
}

.codics-simple-home-left-container-min:hover {
    opacity: 1 !important;
}

.codics-simple-home-left-container-min-font {
    height: 15px;
    width: 15px;
    position: absolute;
    margin: auto;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #333;
    transform: var(--topState);
    transition: .4s;
}

.codics-simple-home-left-box {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    float: left;
}

/* .codics-simple-home-search-tip-box {
    pointer-events: none;
} */

.codics-simple-home-search-tip {
    height: fit-content !important;
    position: absolute !important;
    top: 10px;
    left: 0;
    padding: 7.5px !important;
}

.codics-simple-home-search-tip-item {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 40px;
    font-size: 14px;
    color: #FFF;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    padding-left: 15px !important;
    padding-right: 15px !important;
    transition: .3s;
    text-decoration: none;
}

.codics-simple-home-search-tip-item:hover {
    padding-left: 20px !important;
    background: rgba(255, 255, 255, .3) !important;
}

/* 引擎子项 */
.codics-simple-home-search-engine-item {
    height: 55px;
    width: 25%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-simple-home-search-engine-item-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    color: #FFF;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s;
    user-select: none;
    border: 1px solid rgba(255, 255, 255, .2);
}

.codics-simple-home-search-engine-item-card:hover {
    background: rgba(255, 255, 255, .2);
}

.codics-simple-home-search-carousel {
    height: 150px;
    width: 100%;
    position: absolute !important;
    top: -150px;
    margin: auto;
    left: 0;
    right: 0;
    pointer-events: none;
    transition: .4s;
}

.codics-simple-home-time-box {
    height: 80px;
    width: 100px;
    position: relative;
    float: left;
    perspective: 1000px;
}

.codics-simple-home-time-view{
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    transform-origin: center bottom;
    perspective: 1000px;
}

.codics-simple-home-time-view .flip{
    box-shadow: unset !important;
}

.codics-simple-home-time-view .colon::after,
.codics-simple-home-time-view .colon::before{
    background: rgba(255, 255, 255, .25) !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

.codics-simple-home-time-view .up::after{
    background: rgba(255, 255, 255, .25) !important;
}

.codics-simple-home-time-view .flip .item{
    display: none;
}

.codics-simple-home-time-view .flip .before,
.codics-simple-home-time-view .flip .active {
    display: block !important;
}

.codics-simple-home-time-view .inn{
    background: unset !important;
    text-shadow: unset !important;
    color: rgba(255, 255, 255, 0);
}

.codics-simple-home-time-view .shadow{
    background: unset !important;
}

.codics-simple-home-time-view .down {
    transition: 1s !important;
}

.codics-simple-home-time-view .before .down{
    opacity: 0 !important;
}
.codics-simple-home-time-view .before .up{
    z-index: 2;
    -webkit-animation: turn-up-data-v-250ac99c 0.5s linear both;
    animation: turn-up-data-v-250ac99c 0.5s linear both;
}

.codics-simple-home-time-view .active .inn,
.codics-simple-home-time-view .before .inn{
    background: rgba(255, 255, 255, .25) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    box-sizing: border-box !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    text-shadow: unset !important;
    color: #FFF !important;
}

.codics-simple-home-time-card {
    height: calc(50% - 1px);
    width: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    transform-origin: bottom center;
    transition: 1s;
    background: rgba(255, 255, 255, .25);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    line-height: 78px;
    text-align: center;
    font-size: 50px;
    color: #FFF;
    overflow: hidden;
    perspective: 1000px;
}

.codics-simple-home-time-card span{
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-simple-home-time-upperCard{
    top: 0;
}

.codics-simple-home-time-lowerCard,
.codics-simple-home-time-unfold{
    bottom: 0;
}

.codics-simple-home-time-lowerCard span,
.codics-simple-home-time-unfold span{
    transform: translateY(-40px) !important;
}

.codics-simple-home-time-fold{
    transform: rotateX(-180deg);
    transform-origin: center calc(100% + 1px);
    animation: timeRotate2 10s ease infinite;
    backface-visibility: hidden;
}

.codics-simple-home-time-unfold {
    transform-origin: center 0;
    animation: timeRotate 10s ease infinite;
    backface-visibility: hidden;
}

@keyframes timeRotate {
    0% {
        transform: rotateX(-180deg);
    }
    100% {
        transform: rotateX(0);
    }
}

@keyframes timeRotate2 {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(-180deg);
    }
}

.codics-simple-home-search-carousel-item-wendu-txt{
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: -30%;
    left: -18%;
    right: 0;
    bottom: 0;
    font-size:50px;
    color: #FFF;
    line-height: 150px;
    text-align: center;
    font-family: cursive;
}

.codics-simple-home-search-carousel-item-weather-img{
    height: 95px;
    width: 95px;
    position: absolute !important;
    margin: auto;
    top: -30%;
    left: 0;
    right: -18%;
    bottom: 0;
}
.codics-simple-home-search-carousel-item-weather-city{
    height: 40px;
    width: fit-content;
    position: absolute;
    margin: auto;
    line-height: 40px;
    text-align: center;
    padding: 20px 0;
    color: #FFF;
    cursor: pointer;
    user-select: none;
    pointer-events: all !important;
}

.codics-simple-home-search-carousel-item2::after{
    content: '';
    height: 1px;
    width: 70%;
    position: absolute;
    margin: auto;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, .55);
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

.codics-simple-home-search-carousel-item-info-box{
    height: 40px;
    width: 80%;
    position: absolute;
    margin:auto;
    top: 65%;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-simple-home-search-carousel-item-info-item{
    height: 100%;
    width: 33.333333%;
    position: relative;
    float: left;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #FFF;
}

.codics-simple-home-search-trending{
    top: 50px !important;
    padding: unset !important;
    height: fit-content !important;
    transition: .3s;
    overflow: hidden;
}

.codics-simple-home-search-trending-top{
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-simple-home-search-trending-top-name{
    height: 40px;
    width: fit-content;
    position: relative;
    float: left;
    padding-left: 15px;
    line-height: 40px;
    text-align: center;
    font-size: 12px !important;
    color: #FFF;
    font-weight: 200;
    cursor: pointer;
}

.codics-simple-home-search-trending-top-txt{
    float: right !important;
    padding-right: 15px;
    user-select: none;
}

.codics-simple-home-search-trending-pick input{
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    color: #FFF !important;
    font-size: 12px !important;
    padding: 0 8px !important;
    user-select: none !important;
}

.codics-simple-home-search-trending-pick .el-icon-arrow-down{
    display: none !important;
}

.codics-simple-home-search-trending-content{
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 5px 5px 5px;
}

.codics-simple-home-search-trending-item{
    height: 40px;
    width: 50%;
    position: relative;
    float: left;
    line-height: 40px;
    font-size: 13px !important;
    color: #FFF;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .3s;
    box-sizing: border-box;
    padding-right: 40px;
}

.codics-simple-home-search-trending-item:hover {
    background: rgba(255, 255, 255, .2) !important;
}

.codics-simple-home-search-trending-item-index{
    height: 40px;
    width: 40px;
    position: relative;
    float: left;
    box-sizing: border-box;
    text-align: center;
    line-height: 40px;
    font-size: 11px;
    color: #FFF;
    font-weight: 300;
}

.codics-simple-home-search-trending-item-index::after{
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--backState);
    z-index: -1;
}

.codics-simple-home-search-trending-item-tag{
    width: fit-content !important;
    line-height: 42px !important;
    position: absolute !important;
    font-weight: none !important;
    top: 0;
    right: 15px;
    padding: 0 2.5px !important;
    font-size: 10px !important;
}

.codics-simple-home-search-trending-item-tag::after{
    width: 100% !important;
}

.codics-simple-home-back-img-reload{
    height: 40px;
    width: fit-content;
    position: absolute;
    left: 30px;
    bottom: 30px;
    background: rgba(255, 255, 255, .25);
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    box-sizing: border-box !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    border-radius: 20px;
    overflow: hidden;
    color: #FFF;
    line-height: 38px;
    user-select: none;
    cursor: pointer;
}

.codics-simple-home-back-img-reload-icon{
    height: 100%;
    width: 40px;
    position: relative;
    float: left;
    text-align: center;
    transition: .3s;
}

.codics-simple-home-back-img-reload-txt{
    height: 40px;
    width: 0;
    position: relative;
    float: left;
    font-size: 14px;
    transition: .3s;
}

.codics-simple-home-back-img-reload:hover .codics-simple-home-back-img-reload-txt {
    width: 75px;
}

.codics-simple-home-search-engine-item.moving .codics-simple-home-search-engine-item-card{
    background: transparent !important;
    color: transparent !important;
    border: 1px dashed #FFF !important;
}
/* 搜索引擎顶栏 */
.codics-simple-home-search-engine-top{
    height: 40px;
}

.codics-simple-popover-view{
    background: rgba(255, 255, 255, .25) !important;
    backdrop-filter: blur(4px) !important;
    border: 1px solid rgba(255, 255, 255, .18) !important;
}

.codics-simple-popover-view .popper__arrow{
    /* backdrop-filter: blur(4px) !important; */
    border-bottom-color: rgba(255, 255, 255, .18) !important;
}

.codics-simple-popover-view .popper__arrow::after {
    backdrop-filter: blur(4px) !important;
    border-bottom-color: rgba(255, 255, 255, .25) !important;
}

.codics-simple-popover-view-input input{
    background: rgba(255, 255, 255, .25);
    border: 1px solid rgba(255,255,255,.18);
    backdrop-filter: blur(4px) !important;
}

.codics-simple-home-search-engine-item-delete{
    height: 16px;
    width: 16px;
    position: absolute;
    right: 0;
    top: 2px;
    line-height: 16px;
    text-align: center;
    color: rgba(255,
        255,
        255, .3);
    font-size: 14px;
    transition: .3s;
    cursor: pointer;
}

.codics-simple-home-search-engine-item-delete:hover{
    color: rgba(255,
        255,
        255, .9);
}

.codics-simple-home-bottom-txt{
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #FFF;
    
}
.codics-simple-home-bottom-txt a{
    text-decoration: none;
    color: #FFF;
}

@media (max-width: 768px) {
    .codics-simple-home-time-view{
        top: 0 !important;
        transform: translateX(-50%) scale(.53) !important;
    }
}
</style>