<template>
    <div class="iconfont codics-simple-shortcut-body"></div>
</template>

<script>
export default {

}
</script>

<style>
.codics-simple-shortcut-body {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}
</style>