<template>
    <div class="codics-simple-search-header">
        <div v-show="userInfo != null" class="codics-simple-search-hearder-account-box">
            <el-avatar class="codics-simple-search-hearder-account-himg" :size="30"
                :src="'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
            <div class="codics-simple-search-hearder-account-nick">{{ '未知用户' }}</div>
            <div class="codics-simple-search-hearder-icon iconfont"
                style="margin-left: 0 !important;font-size: 17px !important;">&#xe83d;</div>
            <div class="codics-simple-search-hearder-account-box-menu-box">
                <div class="codics-simple-search-hearder-account-box-menu-item"
                    v-for="(item, index) in ['个人中心', '退出登录']" :key="index" @click="handleMenuClick(item, index)">
                    {{ item }}</div>
            </div>
        </div>
        <div v-show="userInfo==null" class="codics-simple-search-hearder-login-btn" @click="toPage('登录')">登录/注册</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { jumpTo } from '@/utils/myutils';
export default {
    methods: {
        toPage(n, p, q) {

            jumpTo(this, n, p, q);

        },
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
.codics-simple-search-header {
    height: 60px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.codics-simple-search-hearder-account-box {
    height: 40px;
    width: fit-content;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.codics-simple-search-hearder-account-himg {
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
}

.codics-simple-search-hearder-account-nick {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bolder;
    color: #FFF;
    padding-left: 10px;
}

.codics-simple-search-hearder-icon {
    height: 100%;
    width: 40px;
    position: relative;
    float: left;
    box-sizing: border-box;
    text-align: center;
    line-height: 40px;
    color: #FFF;
    font-weight: bolder;
    font-size: 13px;
}

.codics-simple-search-hearder-account-box-menu-box {
    height: fit-content;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 11;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: .3s;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.codics-simple-search-hearder-account-box:hover .codics-simple-search-hearder-account-box-menu-box {
    opacity: 1;
    visibility: visible;
}

.codics-simple-search-hearder-account-box-menu-item {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 40px;
    font-size: 12px;
    color: #FFF;
    font-weight: bolder;
    text-align: center;
    transition: .3s;
}

.codics-simple-search-hearder-account-box-menu-item:hover {
    background: rgba(255, 255, 255, 0.25);
}

.codics-simple-search-hearder-login-btn {
    height: 40px;
    width: fit-content;
    position: relative;
    float: right;
    border-radius: 4px;
    line-height: 38px;
    padding: 0 25px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    cursor: pointer;
    margin-right: 10px;
}
</style>