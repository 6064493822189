<template>
    <div class="codics-sjnr-wdialog-body" @click="changeShow">
        <div class="codics-sjnr-wdialog-dialog" :style="{ 'height': height, 'width': width }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: String,
            default: 'fit-content'
        },
        width: {
            type: String,
            default: '40%'
        },
        engineDialog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeShow() {
            this.$emit('update:show', !this.engineDialog);
        }
    }
}
</script>

<style>
/* 弹窗体 */
.codics-sjnr-wdialog-body {
    height: 100%;
    width: 100%;
    transition: .3s;
    position: fixed;
    z-index: 11111;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: rgba(255,255,255, 0.25);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.codics-sjnr-wdialog-dialog {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, .18);
    border-radius: 5px;
}
</style>